<template>
  <div
    id="dispatchOrderUpdate"
    :style="{padding: form.token ? '24px' : ''}"
  >
    <header-nav
      v-if="!form.token || (form.token && !['02', '03'].includes(form.status))"
      :content="`${$t('pages_router_058')} / ${
        form.status === '01' ? $t('pages_user_111') :
        form.status === '04' ? $t('pages_user_113') :
        $t('pages_user_112')}
      `"
    />
    <div class="common-card-body">
      <template v-if="form.token && ['02', '03'].includes(form.status)">
        <div
          v-if="form.status === '02'"
          class="tip-area"
        >
          <clock-circle-filled style="color: #faad14" class="ic-tip" />
          <div style="margin-top: 8px">{{ $t('pages_user_121') }}</div>
        </div>
        <div
          v-else
          class="tip-area"
        >
          <check-circle-filled class="ic-tip" />
          <div style="margin-top: 8px">{{ $t('pages_user_122') }}</div>
        </div>
      </template>

      <template v-else>
        <div
          v-if="form.auditRemark && !['02', '03'].includes(form.status)"
          class="error-area flex-align"
        >
          <exclamation-circle-outlined class="ic-error" />
          <span>{{ form.auditRemark }}</span>
        </div>
        <div
          v-if="['02', '03'].includes(form.status)"
          class="header-title"
        >{{ $t('pages_user_115') }}:</div>
        <div
          v-else
          class="header-title"
        >{{ $t('pages_user_114', [merchantName]) }}:</div>
        <div class="dispatch-content">{{ form.sendRemark }}</div>
        <div class="apply-area">
          <a-form
            ref="dispatchRef"
            class="ant-round-form dispatch-form"
            layout="vertical"
            :model="form"
            @finish="submitDispatch"
          >
            <a-form-item
              name="recordFile"
              :rules="{required: true, message: $t('common_text_007')}"
            >
              <upload-auto
                ref="uploadRef"
                v-model:file-content="form.recordFile"
                list-type="picture"
                :token="form.token"
                :disabled="['02', '03'].includes(form.status)"
                :accept="'.jpg,.png,.jepg,.zip,.rar,.pdf,.doc,.docx,.xls,.xlsx'"
                :max-count="10"
              />
            </a-form-item>
            <a-form-item
              name="recordRemark"
              :label="$t('pages_user_109')"
            >
              <a-textarea
                v-model:value="form.recordRemark"
                :disabled="['02', '03'].includes(form.status)"
                :placeholder="$t('pages_user_116')"
                allow-clear
              />
            </a-form-item>
            <a-form-item>
              <a-button
                v-if="!['02', '03'].includes(form.status)"
                :loading="loading"
                html-type="submit"
                type="primary"
                shape="round"
                class="apply-btn"
              >{{ $t('common_text_002') }}</a-button>
              <a-button
                v-if="!form.token"
                shape="round"
                style="margin-left: 18px"
                class="apply-btn"
                type="primary"
                ghost
                @click="$router.back(-1)"
              >{{ $t('common_text_003') }}</a-button>
            </a-form-item>
          </a-form>
        </div>
      </template>

    </div>
  </div>
</template>

<script>
import UploadAuto from '@/components/upload-auto'
import HeaderNav from '@/components/header-nav'
import { ref, reactive, toRefs } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { message } from 'ant-design-vue'
import { _user } from '@/api'
import i18n from '@/locale/index'
export default {
  name: 'DispatchOrderUpdate',
  components: {
    'header-nav': HeaderNav,
    'upload-auto': UploadAuto
  },
  setup () {
    const uploadRef = ref(null)
    const router = useRouter()
    const route = useRoute()
    const state = reactive({
      loading: false,
      merchantName: null,
      form: {
        id: route.query.id,
        token: route.query.token,
        sendRemark: '',
        recordFile: '',
        recordRemark: '',
        auditRemark: '',
        status: ''
      }
    })

    const init = async () => {
      const query = {
        id: state.form.id,
        token: state.form.token
      }
      const res = await _user.getDispatchDetail(query)
      if (res.data.succ) {
        const { data } = res.data
        state.form.id = data.id
        state.form.status = data.status
        state.form.sendRemark = data.sendRemark
        state.form.auditRemark = data.auditRemark
        state.form.status = data.status
        state.merchantName = data.merchantName
        if (data.status !== '04') {
          state.form.recordFile = data.recordFile
          state.form.recordRemark = data.recordRemark
          if (data.recordFile) uploadRef.value.fileListRefresh(data.recordFile.split(','))
        }
      } else {
        message.error(res.data.resMsg)
      }
    }

    const submitDispatch = async () => {
      state.loading = true
      const res = state.form.status === '04'
        ? await _user.modifyMaterial(state.form)
        : await _user.submitMaterial(state.form)
      state.loading = false
      if (res.data.succ) {
        message.success(i18n.global.t('pages_user_072'))
        if (state.form.token) {
          state.form.id = null
          init()
        } else {
          router.back(-1)
        }
      } else {
        message.error(res.data.resMsg)
      }
    }

    init()

    return {
      uploadRef,
      submitDispatch,
      ...toRefs(state)
    }
  }
}
</script>

<style lang="less" scoped>
#dispatchOrderUpdate {
  .common-card-body {
    padding: 18px 24px;
    text-align: left;
    .tip-area {
      text-align: center;
      margin-top: 48px;
      font-size: 18px;
      font-weight: 600;
      .ic-tip {
        color: #52c41a;
        margin-right: 8px;
        font-size: 64px;
      }
    }
    .error-area {
      width: 488px;
      padding: 12px 24px;
      border-radius: 48px;
      color: @error-color;
      background: #fef0f0;
      margin-bottom: 24px;
      word-break: break-word;
      .ic-error {
        font-size: 28px;
        margin-right: 12px;
      }
    }
    .header-title, .dispatch-content {
      white-space: break-spaces;
      margin-bottom: 24px;
    }
    .header-title {
      font-size: 15px;
      font-weight: 600;
    }
    .dispatch-form {
      width: 488px;
      .apply-btn {
        min-width: 138px;
        margin-top: 24px;
      }
    }
  }
}
</style>
